$(function() {

  var dirRtlFlag = false;
  if ($("html").attr('dir') == 'rtl') {
    dirRtlFlag = true;
  }


  $('.case').slick({
   slidesToShow: 4, 
   slidesToScroll: 1,
   dots: false,
   infinite: true,
   speed: 1000,
   rtl: dirRtlFlag,
   arrows: true,
   autoplay: false,
   autoplaySpeed: 5000,
   cssEase: 'linear',
   pauseOnHover: false,
  responsive: [
   {
     breakpoint: 870,
     settings: {
       arrows: true,
       slidesToShow: 3,
       slidesToScroll: 1,
     }
   },
   {
     breakpoint: 575,
     settings: {
       arrows: true,
       slidesToShow: 2,
       slidesToScroll: 1
     }
   }
 ]
 });


});
