$(function(){
  $(document).ready(function() {
     $("#video").lightGallery();
     $('.lgallery_active').lightGallery();


     $(".mbtn").click(function() {
        var id = $(this).attr("id");
        if (id == "paper"){
          $("#tv").removeClass("active");
          $("#paper").addClass("active");


          // tabs
          $(".brown_video_ico").show();
          $(".white_video_ico").hide();

          $(".white_paper_ico").show();
          $(".brown_paper_ico").hide();

          $("#video").fadeOut( 'fast', function(){
                 $("#lgallery").show();

          });

        } else {
          $("#paper").removeClass("active");
          $("#tv").addClass("active");

          // tabs
          $(".brown_video_ico").hide();
          $(".white_video_ico").show();

          $(".white_paper_ico").hide();
          $(".brown_paper_ico").show();

          $("#lgallery").fadeOut( 'fast', function(){
                 $("#video").show();


          });

        }

     });


   });



    $(".col-md-3.__item.pdf").click(function(e) {
      alert( "Handler for .click() called. it" );
    });


});
