function show(item_num){


  var modal_filler = $("#selected_item_" + item_num).html();
  $("#myModal-inner-html").html(modal_filler);
  $('#myModal').modal('show');

}


$( document ).ready(function() {
    youtubeImg();
});


function youtubeImg(){


    $( ".img_prev" ).each(function( index ) {
      
        try {
          var youtube_link = $(this).attr('data-tube');
          var youtube_video_id = youtube_link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
          if (youtube_video_id.length == 11) {
              var video_thumbnail = "https://img.youtube.com/vi/"  + youtube_video_id + "/0.jpg";
              $(this).attr('src', video_thumbnail);
          }

        }
        catch(err) {

        }



    });


}
